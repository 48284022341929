import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import TextFieldZipcode from '../Inputs/TextField/TextFieldZipcode'

import SelectTrim from "../../components/Inputs/Select/SelectTrim";
import SelectAnnualMileage from "../../components/Inputs/Select/SelectAnnualMileage"
import SlideEquivalentGasMPG from "../../components/Inputs/Slider/SlideEquivalentGasMPG";
import SlideElectricityPrice from "../../components/Inputs/Slider/SlideElectricityPrice";
import SlideFuelPrice from "../../components/Inputs/Slider/SlideFuelPrice";
import './Header.scss'

const Header = ({vehicle}) => {

    const inputs = [
        <Box className='zipcodeBox'><TextFieldZipcode /></Box>,
        <Box className='msrpBox'><SelectTrim /></Box>,
        <Box className='annualMileageBox'><SelectAnnualMileage /></Box>,
        <Box className='mpgBox'><SlideEquivalentGasMPG type={vehicle.fuel} /></Box>,
        <Box className='electricityBox'><SlideElectricityPrice /></Box>,
        <Box className='fuelBox'><SlideFuelPrice /></Box>,
    ]
    
    const renderInputs = (inputs) => {
        return inputs.map(input => {
            return (
                <Grid key={input.type.name || input.props.children.type.name} item style={{maxWidth:'300px', margin: '0 auto'}}>
                    {input}
                </Grid>
            )
        })
    }

    return (
        <Box className='headerDesk' sx={{width: '100%', height: 'auto', marginBottom: '50px'}}>
            <Typography sx={{color: ' #AF6249', fontSize: '36px', fontWeight: '400', marginBottom: '24px', fontFamily: "GenesisSansHead"}}>
                ESTIMATE THE BENEFITS OF DRIVING ELECTRIC
            </Typography>

            <Box className='inputContainer' sx={{width: 'auto', height: '70px', background: '#F9F9F9', border: '1px solid #DBDBDB', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                {renderInputs(inputs)}
            </Box>
        </Box> 
    )
}

export default Header
