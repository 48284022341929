import DoughnutGraph from "../components/Graphs/DoughnutGraph/DoughnutGraph"
import { useSelector } from 'react-redux';
import FuelCost from "../functions/vehicle/costOfOwnership/FuelCost";
import { incentiveSavingsOfGivenTypes } from "../functions/incentive/incentiveHelperFunctions"
import { FormatAsDollars } from "../utils/Format";

const CostOfOwnership = ({
    vehicle,
    activeTrim,
}) => {
    const { annualMileage, equivalentGasMPG, electricityPrice, fuelPrice, percentElectric, numYears } = useSelector(state => state.user)

    const fuelSavingsData = FuelCost.totalCost(annualMileage,equivalentGasMPG, vehicle, electricityPrice, fuelPrice, percentElectric) * numYears

    const federalStateUtilityIncentiveSavings = incentiveSavingsOfGivenTypes(vehicle.incentives, ["Country", "state", "power supplier"])

    // Array of Incentive, Fuel Savings, Maintenance
    const data = [federalStateUtilityIncentiveSavings, fuelSavingsData]
    const labels = ["Potential incentives", "Potential fuel savings"];

    const totalSavings = FormatAsDollars(data.reduce((acc, num) => acc + num, 0))    

    return (
        <div>
          <DoughnutGraph
            subHeader={`${totalSavings}`}
            graphData={data}
            labels={labels}
            vehicle={vehicle}
            activeTrim={activeTrim}
          />
        </div>
    )
}

export default CostOfOwnership
