import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import FormControl from '@mui/material/FormControl';
import { InputAdornment, OutlinedInput } from '@mui/material';
import CustomizedToolTip from "../CustomizedToolTip/CustomizedToolTip"
import { useState, useEffect } from 'react'

const InputSlider = ({
    title,
    value,
    onChange,
    min,
    max,
    step = 10,
    startAdornment,
    endAdornment,
    maxLength,
    tooltip,
    tooltipPlacement,
    onBlur,
    isMpg
}) => {
       const [inputValue, setInputValue] = useState('');

       useEffect(() => {
         const formattedValue = isMpg ? parseFloat(value).toFixed(0) : parseFloat(value).toFixed(2);
         setInputValue(formattedValue);
       }, [value, isMpg]);

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        setInputValue(inputValue);

        // Only update the parent component value if the input value is valid
        if (!isNaN(inputValue)) {
            onChange(parseFloat(inputValue));
        }
    };

    const handleBlur = () => {
        onBlur(parseFloat(inputValue));
    };

    const handleSliderChange = (event, newValue) => {
        setInputValue(newValue);
        onChange(newValue);
    };

    return (
        <Box sx={{position: 'relative'}}>
            <Typography style={{color: "#141414"}} sx={{ textAlign: "left", fontWeight: 400, fontSize: "1rem", marginBottom: "12px", whiteSpace: "nowrap", lineHeight: "1em", position: "relative", top: "1px" }} id="input-slider" gutterBottom>
                {title.toUpperCase()}
                {tooltip && <CustomizedToolTip title={tooltip} placement={tooltipPlacement} isInput isChart/>}
            </Typography>
            <Grid container spacing={4} alignItems="center">
                <Grid item xs={8} sx={{paddingTop: '24px !important'}}>
                    <Slider
                        sx={{
                            color: "#141414",
                            '& .MuiSlider-rail': {
                                color: '#dbdbdb',
                            },
                        }}
                        value={Number(value)}
                        onChange={handleSliderChange }
                        aria-labelledby="input-slider"
                        step={step}
                        min={min}
                        max={max}
                        size="small"
                    />
                </Grid>
                <Grid item style={{ marginBottom: "0px", paddingLeft: '15px' }} xs={4}>
                    <FormControl variant="outlined">
                        <OutlinedInput
                            className="ARE YOU HERE"
                            id="outlined-adornment-weight"
                            value={inputValue}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            startAdornment={<InputAdornment position="start">{startAdornment}</InputAdornment>}
                            endAdornment={<InputAdornment position="end" className="endAdornment">{endAdornment}</InputAdornment>}
                            aria-describedby={`outlined-${title}-helper-text`}
                            inputProps={{
                                'aria-label': title,
                                maxLength: maxLength
                            }}
                            sx={{
                                top: "-6px",
                                position: "relative",
                                color: "#141414",
                                fontSize: "16px",
                                background: '#fff',
                                fontWeight: 400,
                                '& .MuiInputBase-input': {
                                    padding: "11px 0 11px",
                                    minWidth:'30px',
                                    height: '26px',
                                },
                                '& .MuiInputAdornment-root': {
                                   marginRight: "0px",
                                },
                                paddingLeft: "5px",
                                paddingRight: "0px",
                                '& .Mui-focused': {
                                    fontSize: "40px"
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderRadius: "0"
                                }
                            }}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    );
}

export default InputSlider;

InputSlider.propTypes = {
    title: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
};