import TextField from "../TextField"
import { getActiveTrim, getZipcode } from "../../../redux/selectors"
import { useDispatch, useSelector } from 'react-redux';
import { updateZipcode } from '../../../redux/Slices/userSlice';
import GaTracker from "../../../utils/GaTracker";
import { getTooltips } from "../../../redux/selectors"

const TextFieldZipcode = () => {
    const zipcode = useSelector(getZipcode)
    const dispatch = useDispatch()
    const tooltip = useSelector(getTooltips)
    const activeTrim = useSelector(getActiveTrim)

    const changeZipcode = (zipcode) => {
        GaTracker.trackEvent({
          category: "Update",
          action: "Updated Zip code",
          label: `Updated Zip code Input in ${activeTrim.handle}`,
        });

        dispatch(updateZipcode(zipcode))
    }
    
    return (
            <TextField 
                title="ZIP CODE"
                defaultValue={zipcode}
                onChange={changeZipcode}
                maxLength={5}
                placement="bottom"
                tooltip={tooltip?.zipcode_tip}
            />
    )
}

export default TextFieldZipcode
