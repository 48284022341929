import Slider from "../Slider"
import { getActiveTrim, getPercentElectric } from "../../../redux/selectors"
import { useDispatch, useSelector } from 'react-redux';
import { updatePercentElectric } from "../../../redux/Slices/userSlice";
import GaTracker from "../../../utils/GaTracker";

const SlidePercentElectric = () => {
    const dispatch = useDispatch()
    const percentElectric = useSelector(getPercentElectric)
    const maxNumber = 100;
    const activeTrim = useSelector(getActiveTrim)

    const updateElectricPercentage = (percent) => {
        GaTracker.trackEvent({
          category: "Update",
          action: "Updated Electric Percentage",
          label: `Updated Electric Percentage Slider in ${activeTrim.handle}`,
        });

        if (!isNaN(Number(percent))) {
            if (percent > maxNumber) {
                dispatch(updatePercentElectric(maxNumber))
            } else {
                dispatch(updatePercentElectric(Number(percent)))
            }
        }
    }

    return (
        <Slider
            title={"Electric miles driven"}
            value={percentElectric}
            onChange={updateElectricPercentage}
            min={0}
            max={100}
            step={10}
            endAdornment={"%"}
            maxLength={3}
            tooltip="Estimate what percentage of your driving miles will be under pure electric power."
            tooltipPlacement="bottom"
        />
    )
}

export default SlidePercentElectric
