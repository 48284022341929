import BarGraph from "../components/Graphs/BarGraph/BarGraph"
import calcEmissions from '../functions/vehicle/emissions/calcEmissions';
import { LBC02_PER_GALLON_OF_GAS } from "../data/assumptions/Assumptions"
import { useSelector } from 'react-redux';

const Emissions = ({ vehicle, location }) => {
    const { annualMileage, equivalentGasMPG, percentElectric, numYears } = useSelector(state => state.user)

    const electricityEmissionsLocation = location?.regional_electricity?.[0]?.emissions?.emissions_co2

    const gasEmission = calcEmissions.gasEmitted(annualMileage, equivalentGasMPG, LBC02_PER_GALLON_OF_GAS.value) * numYears

    const evEmission = calcEmissions.evEmitted(annualMileage, vehicle.electric_efficiency, electricityEmissionsLocation, vehicle.fossil_fuel_efficiency, LBC02_PER_GALLON_OF_GAS.value, vehicle, percentElectric) * numYears

    const graphData = [evEmission, gasEmission]
    const labels = [[`${vehicle.make}`, vehicle.model], ['Gas-only', 'Equivalent'] ]
    
    if(vehicle.model === 'GV70') {
      labels[0] = ['Electrified', 'GV70']
    }

    const reduction = parseFloat(((gasEmission - evEmission) / gasEmission) * 100, 2).toFixed(2)

    return (
        <div id='emissionsTab'>
          <BarGraph
            graphData={graphData}
            labels={labels}
            reduction={reduction}
          />
        </div>
    )
}

export default Emissions
