import React from "react";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "chart.js/auto";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import CustomizedToolTip from "../../CustomizedToolTip/CustomizedToolTip";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { getTooltips } from "../../../redux/selectors"
import { useSelector } from 'react-redux';

const BarGraph = ({
  reduction,
  graphData,
  labels,
  backgroundColor = ["#DBDBDB", "#141414"],
}) => {
  const { width } = useWindowDimensions();
  const tooltip = useSelector(getTooltips)

  const options = {
    layout: {
      padding: {
        top: 40,
      },
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          drawBorder: false,
          borderColor: "green",
          borderWidth: 5,
          display: false,
        },
        ticks: {
          display: false,
          font: {
            family: "GenesisSansText",
            size: "14px",
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#141414",
          font: {
            family: "GenesisSansText",
            size: "14px",
          },
        },
      },
    },
    responsive: true,
    plugins: {
      datalabels: {
        font: {
          size: width < 1600 ? 14 : 18,
          family: "GenesisSansText",
          lineHeight: width < 1450 ? '20px' : '40px',
        },
        color: "#141414",
        display: true,
        anchor: "end",
        align: "end",
        textAlign: width < 1450 ? 'center' : 'start',
        offset: 0,
        useHtml: true,
        formatter: function (value, ctx) {
          return width < 1450 ? Number(value.toFixed(0)).toLocaleString("en-US") + "\nlbs. CO₂ / yr" : Number(value.toFixed(0)).toLocaleString("en-US") + " lbs. CO₂ / yr";
        },
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.formattedValue;
            return label + " lbs. CO₂";
          },
          title: ([{ label }]) => (label ? label.replace(",", " ") : ""),
        },
      },
      // title: {
      //     display: true,
      //     position: "top",
      //     text: header,
      // },
    },
  };

  // Specific Change for Label
  labels.map((label, index) => {
    if (label === "2022 TUCSON Plug-In Hybrid") {
      labels[index] = ["2022 TUCSON", "Plug-In Hybrid"];
    }

    if (label === "2022 KONA Electric") {
      labels[index] = ["2022 KONA", "Electric"];
    }

    if (label === "2022 IONIQ 5") {
      labels[index] = ["2022", "IONIQ 5"];
    }

    if (label === "2022 IONIQ Plug-in Hybrid") {
      labels[index] = ["2022 IONIQ", "Plug-in Hybrid"];
    }

    if (label === "2022 SANTA FE Plug-in Hybrid") {
      labels[index] = ["2022 SANTA FE", "Plug-in Hybrid"];
    }

    return "";
  });

  const data = {
    labels,
    datasets: [
      {
        // label: 'Dataset 1',
        data: graphData,
        backgroundColor: backgroundColor,
      },
    ],
  };
  return (
    <Grid container rowSpacing={4} justifyContent='center'>
      <Grid item xs={12}>
        <Typography sx={{ fontSize: "24px", fontWeight: 400, color: "#141414", fontFamily: "GenesisSansHead" }}>
          EMISSIONS<CustomizedToolTip title={tooltip?.emissions_tip} />
        </Typography>
      </Grid>
      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', margin: width < 1600 ? '18px 0 0 0' : '18px 0 40px 0'}}>
        <Typography sx={{ fontSize: "16px", fontWeight: 400, color: "#141414", maxWidth: '33ch' }}>
          Going all-electric can reduce your emissions from driving by up to <span style={{color: '#AF6249'}}>{Math.round(reduction)}%</span> over five years.
        </Typography>
      </Box>
      <Grid item xs={width < 1025 ? 12 : 8} sx={{ height: "300px", paddingTop: '0 !important' }}>
        <Bar options={options} data={data} plugins={[ChartDataLabels]} />
      </Grid>
    </Grid>
  );
};
export default BarGraph;

BarGraph.propTypes = {
  header: PropTypes.string,
  subHeader: PropTypes.string,
  graphData: PropTypes.array,
  labels: PropTypes.array,
  backgroundColors: PropTypes.array,
};
