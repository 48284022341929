import Select from "../Select"
import { getTrims, getActiveTrim } from "../../../redux/selectors"
import { useDispatch, useSelector } from 'react-redux';
import { updateActiveTrim } from "../../../redux/Slices/userSlice";
import GaTracker from "../../../utils/GaTracker";
import { FormatAsDollars } from "../../../utils/Format";
import { getTooltips } from "../../../redux/selectors"

const SelectTrim = () => {
    const dispatch = useDispatch()
    const trims = useSelector(getTrims)
    const activeTrim = useSelector(getActiveTrim)
    const tooltip = useSelector(getTooltips)
    const options = trims.map(trim => {
        return {
            value: trim.name,
            label: trim.displayName ? trim.displayName : `${trim.name} ${FormatAsDollars(trim.msrp)}`
        }
    }).sort((a,b) => a - b)

    const changeTrim = (trimName) => {
        const setTrim = trims.find(trim => trim.name === trimName)

        GaTracker.trackEvent({
            category: "Select Trim",
            action: "Selected a Trim",
            label: `Clicked ${trimName} in ${activeTrim.handle}`
        });

        dispatch(updateActiveTrim(setTrim))
    }

    return (
        <Select
            title="POWERTRAIN AND STARTING MSRP"
            options={options}
            value={activeTrim.name}
            onChange={changeTrim}
            tooltipPlacement="bottom"
            tooltip={tooltip?.trimStartingMSRP_tip}
        />
    );
}

export default SelectTrim
