import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

const Footer = ({isMobile}) => {
    return (
        <Box sx={{width: '100%', height: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: isMobile ? '20px' : '40px', marginBottom: isMobile ? '60px' : 0}}>
            <Typography fontSize={'14px'} color={'#6F6F6F'} fontWeight={400} maxWidth='95%' textAlign={'center'}>
            Potential tax credits and incentives are referenced for informational purposes only. This information does not constitute tax, financial or legal advice. Please consult with your tax, financial or legal professional to determine eligibility, amount of credits and/or incentives available, if any, and further details. Tax credits and incentives are not within Genesis' control and are subject to change or expire without notice. Estimated potential fuel savings and carbon emission estimates are based on a 5-year analysis and are provided for informational purposes only. Actual fuel savings, if any, and carbon emissions will vary. 
            </Typography>
        </Box>
    )
}

export default Footer
