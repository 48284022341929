import Slider from "../Slider"
import { getActiveTrim, getEquivalentGasMPG } from "../../../redux/selectors"
import { useDispatch, useSelector } from 'react-redux';
import { updateEquivalentGasMPG } from "../../../redux/Slices/userSlice";
import GaTracker from "../../../utils/GaTracker";
import { getTooltips } from "../../../redux/selectors"

const SlideEquivalentGasMPG = () => {
    const dispatch = useDispatch()
    const equivalentGasMPG = useSelector(getEquivalentGasMPG)
    const maxNumber = 80
    const tooltip = useSelector(getTooltips)
    const activeTrim = useSelector(getActiveTrim)

    const updateEquivalentGasEfficiency = (mpg) => {
        GaTracker.trackEvent({
          category: "Update",
          action: "Updated Equivalent Gas Efficiency",
          label: `Updated Equivalent Gas Efficiency Slider in ${activeTrim.handle}`,
        });

        if (!isNaN(Number(mpg))) {
            if (mpg > 80) {
                dispatch(updateEquivalentGasMPG(Number(maxNumber)))
            } else {
                dispatch(updateEquivalentGasMPG(Number(mpg)))
            }
        }
    }

    return (
        <Slider
            title={"Comparison MPG"}
            value={equivalentGasMPG}
            onChange={updateEquivalentGasEfficiency}
            onBlur={updateEquivalentGasEfficiency}
            min={5}
            max={maxNumber}
            step={5}
            maxLength={2}
            tooltip={tooltip?.comparison_mpg_tip}
            isMpg
        />
    )
}

export default SlideEquivalentGasMPG
