const eligibleIncentives = (incentives) => {
    return incentives.filter(incentive => {
        return incentive?.evaluation?.eligibility === "eligible" && incentive?.support_for === "Vehicle purchase"
    })
}

const filterIncentives = (incentives, grantorType) => {
    return incentives ? incentives.filter(incentive => incentive.grantor_type.toLowerCase() === grantorType.toLowerCase()) : [];
}

const highestAmountIncentive = (incentives) => {
    return incentives.reduce((a, b) => {
        return (a?.evaluation?.amount_in_purchase >  b?.evaluation?.amount_in_purchase) ? a : b
    }, null)
}

const highestEligibleAmountIncentiveGivenType = (incentives, grantorType) => {
    return highestAmountIncentive(eligibleIncentives(filterIncentives(incentives, grantorType)))
}

const highestFederalStateUtilityIncentive = (incentives) => {
    const validIncentives = eligibleIncentives(incentives)
    const highestFederalIncentive = highestAmountIncentive(filterIncentives({incentives: validIncentives, grantorType: "Country"}))
    const highestStateIncentive = highestAmountIncentive(filterIncentives({incentives: validIncentives, grantorType: "State"}))
    const highestUtilityIncentive = highestAmountIncentive(filterIncentives({incentives: validIncentives, grantorType: "Power Supplier"}))

    return {
        country: highestFederalIncentive,
        state: highestStateIncentive,
        utility: highestUtilityIncentive,
    }
}

// returns the sum of the highest eligible incentive for each given grantor_type
const incentiveSavingsOfGivenTypes = (incentives, grantorTypes) => {
    let highestIncentiveTypes = grantorTypes.map(grantorType => {
        return highestEligibleAmountIncentiveGivenType(incentives, grantorType)
    })

    highestIncentiveTypes = highestIncentiveTypes.filter(incentive => incentive !== null)

    return highestIncentiveTypes.reduce(function (previousValue, currentValue) {
        return previousValue + currentValue.evaluation.amount_in_purchase
    }, 0)
}

export {
    eligibleIncentives,
    filterIncentives,
    highestAmountIncentive,
    highestFederalStateUtilityIncentive,
    highestEligibleAmountIncentiveGivenType,
    incentiveSavingsOfGivenTypes,
}
