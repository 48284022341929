import React from "react";
import PropTypes from "prop-types";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { FormatAsDollars } from "../../../utils/Format";
import CustomizedToolTip from "../../CustomizedToolTip/CustomizedToolTip";
import { getTooltips } from "../../../redux/selectors"
import { useSelector } from 'react-redux';

const DoughnutGraph = ({
  subHeader,
  graphData,
  labels = ["Incentives", "Fuel savings", "Maintenance"],
  backgroundColor = ["#AF6249", "#141414"],
}) => {
  const tooltip = useSelector(getTooltips)

  const data = {
    maintainAspectRatio: false,
    responsive: false,
    labels: labels,
    datasets: [
      {
        data: graphData,
        backgroundColor: backgroundColor,
      },
    ],
  };

  const options = {
    cutout: '35%',
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label({ label, raw }) {
            return `${label} ${FormatAsDollars(raw)}`;
          },
        },
      },
      legend: {
        position: "bottom",
        labels: {
          boxHeight: 0,
          boxWidth: 0,
          color: "transparent",
          font: {
            family: "GenesisSansText",
            size: "13px",
          },
          filter: (legendItem, data) =>
            data.datasets[0].data[legendItem.index] !== 0,
        },
        onClick: function () {
          return false;
        },
      },
    },
  };

  return (
    <Grid container justifyContent={'center'}>
      <Grid item xs={12}>
        <Typography sx={{ fontSize: "24px", fontWeight: 400, color: "#141414", fontFamily: "GenesisSansHead" }}>
          POTENTIAL SAVINGS<CustomizedToolTip title={tooltip?.savings_tip} />
        </Typography>
      </Grid>
      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', margin: '18px 0 40px 0'}}>
        <Typography sx={{ fontSize: "16px", fontWeight: 400, color: "#141414", maxWidth: '30ch' }}>
          You could enjoy as much as <span style={{color: '#AF6249'}}>{subHeader}</span> in savings over five years.
        </Typography>
      </Box>
      <Grid item xs={8}>
        <Doughnut 
          data={data} 
          options={options} 
        />
      </Grid>
      <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center'}}>
        <Box>
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <div
              style={{
                width: "16px",
                height: "16px",
                backgroundColor: backgroundColor[0],
                margin: "0 5px 0 0",
                position: "relative",
                top: "-1px"
              }}
            />
            <div>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#141414",
                  margin:"5px 0",
                  whiteSpace: "nowrap"
                }}
              >
                {labels[0]} <CustomizedToolTip title={tooltip?.potentialIncentives_tip} isChart/>
              </Typography>
            </div>
          </Box>

          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <div
              style={{
                width: "16px",
                height: "16px",
                backgroundColor: backgroundColor[1],
                margin: "0 5px 0 0",
                position: "relative",
                top: "-1px"
              }}
            />
            <div>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#141414",
                  margin:"5px 0",
                  whiteSpace: "nowrap"
                }}
              >
                {labels[1]} <CustomizedToolTip title={tooltip?.potentialSavings_tip} isChart/>
              </Typography>
            </div>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default DoughnutGraph;

DoughnutGraph.propTypes = {
  header: PropTypes.string,
  subHeader: PropTypes.string,
  graphData: PropTypes.array,
  labels: PropTypes.array,
  backgroundColors: PropTypes.array,
};
