import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import GaTracker from "../../utils/GaTracker";
import { useSelector } from 'react-redux';
import { getActiveTrim } from '../../redux/selectors';

const IncentiveCard = ({ name, grantor, amount, description, link, isLast}) => {
    const activeTrim = useSelector(getActiveTrim)

    const handleClick = () => {
        GaTracker.trackEvent({
            category: "Incentives",
            action: "Clicked Incentive Card",
            label: `${name} from ${grantor} in ${activeTrim.handle}`,
        });
    }

    const notAvailable = description.includes('not available')

    return (
        <Card
            sx={{
                minWidth: 275,
                marginBottom: "32px",
                borderRadius: 0,
                boxShadow: "none"
            }}
        >
            {!isLast && <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Box sx={{background: '#DBDBDB', height: '1px', width: '60%', marginBottom: '32px' }}/>
            </Box>}   
            <CardContent style={{padding:"16px 48px"}}>
                <Box sx={{display: 'flex', alignItems: 'baseline', justifyContent: 'center'}}>
                    <Typography sx={{ fontSize: 18, fontWeight: 400, color: '#141414', fontFamily: "GenesisSansHead" }} color="text.secondary" gutterBottom>
                        {grantor.toUpperCase()}: 
                    </Typography>
                    <Typography 
                        sx={{ fontFamily: "GenesisSansHead", fontSize: 18, fontWeight: 400, color: "#AF6249", display: 'flex', alignItems: 'center', margin: '0px 4px' }} 
                        color="text.secondary"
                    >
                        {
                            amount && amount.replace("$", '') === '0' 
                                ? ` ${amount}` 
                                :   <>
                                        <Typography sx={{color: '#141414', marginRight: '4px', fontFamily: "GenesisSansHead", fontSize: 18}}>UP TO</Typography> {amount}
                                    </>
                        }
                    </Typography>
                </Box>
                    {!notAvailable ? 
                        <Link
                            onClick={handleClick}
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                            underline="#141414"
                            style={{
                                color: "#141414",
                                fontWeight: "400",
                                fontSize: "14px",
                                cursor: 'pointer',
                                fontFamily: 'GenesisSansText'
                            }}
                        >
                            Learn more
                        </Link>
                        :
                        <Typography sx={{ fontSize: 14, fontWeight: 400, color: '#141414' }}>Check back for future incentives</Typography>
                    }
            </CardContent>
            {!isLast && <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Box sx={{background: '#DBDBDB', height: '1px', width: '60%',  marginTop: '32px'}}/>
            </Box>}
        </Card>
    )
}

export default IncentiveCard
