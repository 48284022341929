import React from 'react'
import Grid from '@mui/material/Grid';
import Incentives from '../../tabs/Incentives';
import CostOfOwnership from '../../tabs/CostOfOwnership';
import Emissions from '../../tabs/Emissions';
import './MainPage.scss'

const MainPage = ({ vehicleIncentives, state, vehicle, activeTrim, location }) => {

    return(
        <Grid container justifyContent="center" className="grid-wrapper">
            <Grid md={4} xs={12} className="grid-left">
                <Incentives 
                    vehicleIncentives={vehicleIncentives}
                    state={state}
                />
            </Grid>
            <Grid md={4} xs={11} className="grid-center" >
                <CostOfOwnership 
                    vehicle={vehicle}
                    activeTrim={activeTrim}
                />
            </Grid>
            <Grid md={4} xs={12} className="grid-right" padding='0 0 0 100px'>
                <Emissions 
                    vehicle={vehicle}
                    location={location}
                />
            </Grid>
        </Grid>
    )
}

export default MainPage