import IconButton from '@mui/material/IconButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip enterTouchDelay={0} {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#141414",
      color: '#fff',
      boxShadow: theme.shadows[1],
      fontSize: 13,
      fontWeight: 400,
      padding: "20px",
      fontStyle: 'GenesisSansText-Tooltip',
    },
  }));

export default function CustomizedToolTip({ title, placement, isChart, isInput }) {
    return (
        <LightTooltip title={title} placement={placement}>
            <IconButton sx={{padding: "0px", color: '#141414', fontFamily: 'GenesisSansHead', marginTop: '-6px', fontSize: isChart ? '13px' : '24px'}}>
                *
            </IconButton>
        </LightTooltip>
    );
}
