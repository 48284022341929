import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import TextFieldZipcode from '../Inputs/TextField/TextFieldZipcode'
import { isPHEV } from "../../functions/vehicle/vehicleHelper";

import SelectTrim from "../../components/Inputs/Select/SelectTrim";
import SelectAnnualMileage from "../../components/Inputs/Select/SelectAnnualMileage"
import SlideEquivalentGasMPG from "../../components/Inputs/Slider/SlideEquivalentGasMPG";
import SlideElectricityPrice from "../../components/Inputs/Slider/SlideElectricityPrice";
import SlideFuelPrice from "../../components/Inputs/Slider/SlideFuelPrice";
import SlidePercentElectric from "../../components/Inputs/Slider/SlidePercentElectric";
import './MobileHeader.scss'
import { useState } from 'react';
import { CloseIcon } from '../../assets/icons/icons'

const Header = ({vehicle}) => {
    const [isOpen, setIsOpen] = useState(false)

    const inputs = [
        <TextFieldZipcode />,
        <SelectTrim />,
        <SelectAnnualMileage />,
        <SlideEquivalentGasMPG type={vehicle.fuel} />,
        <SlideElectricityPrice />,
        <SlideFuelPrice />,
    ]

    isPHEV(vehicle) && inputs.push(<SlidePercentElectric />)
    
    const renderInputs = (inputs) => {
        return inputs.map(input => {
            return (
                <Grid key={input.type.name} item xs={12} style={{ margin: '0 0 24px 0'}}>
                    {input}
                </Grid>
            )
        })
    }

    return (
        <Box className='headerMobile'>
            <div className='topSide'>
                <Typography sx={{color: ' #AF6249', fontSize: '36px', fontWeight: '400', marginBottom: '24px', maxWidth: '70%', marginTop: '60px', fontFamily: "GenesisSansHead"}}>
                    HOW MUCH CAN YOU SAVE GOING ELECTRIC?
                </Typography>

                <Box onClick={() => setIsOpen(true)}>
                    <Typography sx={{color: '#141414', fontSize: '14px', fontWeight: '400', marginBottom: '24px', textDecoration: 'underline'}}>
                        PERSONALIZE RESULTS
                    </Typography>
                </Box>
            </div>

            <div className={isOpen ? 'lateralMenu open' : 'lateralMenu'}>
                <div className='header'>
                    <Typography sx={{color: ' #141414', fontSize: '24px', fontWeight: '400'}} >FILTERS</Typography>
                    <div onClick={() => setIsOpen(false)}>
                        <CloseIcon />
                    </div>
                </div>

                <div className='subHeader'>
                    <Typography sx={{color: ' #141414', fontSize: '16px', fontWeight: '400', textAlign: 'left'}} >
                        Personalize incentives, savings, and emissions estimates to your unique lifestyle by adjusting the filters below.
                    </Typography>
                </div>

                <div className='body'>
                    {renderInputs(inputs)}
                </div>

                <div className='footer' onClick={() => setIsOpen(false)}>
                    <button> Update </button>
                </div>
            </div>
        </Box> 
    )
}

export default Header
