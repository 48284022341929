import Select from "../Select"
import { useDispatch, useSelector } from 'react-redux';
import { updateAnnualMileage } from "../../../redux/Slices/userSlice";
import { getActiveTrim, getAnnualMiles } from "../../../redux/selectors"
import GaTracker from "../../../utils/GaTracker";
import { getTooltips } from "../../../redux/selectors"

const SelectAnnualMileage = () => {
    const dispatch = useDispatch()
    const annualMiles = useSelector(getAnnualMiles)
    const tooltip = useSelector(getTooltips)
    const activeTrim = useSelector(getActiveTrim)

    const updateAnnualMiles = (miles) => {
        GaTracker.trackEvent({
          category: "Update",
          action: "Updated Annual Miles",
          label: `Updated Annual Miles Input in ${activeTrim.handle}`,
        });

        dispatch(updateAnnualMileage(Number(miles)))
    }

    const annualMileageOptions = [
        { value: 10000, label: '10,000' },
        { value: 12000, label: '12,000' },
        { value: 15000, label: '15,000' },
        { value: 20000, label: '20,000' },
        { value: 25000, label: '25,000' },
        { value: 30000, label: '30,000' },
    ]

    return (
        <Select
            title="Annual mileage"
            options={annualMileageOptions}
            value={annualMiles}
            onChange={updateAnnualMiles}
            tooltipPlacement="bottom"
            tooltip={tooltip?.annual_milage_tip}
        />
    )
}

export default SelectAnnualMileage
