import { useState, useEffect } from 'react'
import './App.css';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useGetVehicleQuery, useGetLocationQuery } from "./redux/services/unifiedAPIQuery"
import { useDispatch, useSelector } from 'react-redux';
import { updateZipcode, updateTrims, updateActiveTrim, updateElectricityPrice, updateFuelPrice } from './redux/Slices/userSlice';
import { updatetooltips } from './redux/Slices/tooltipsSlice';
import { getZipcode, getActiveTrim } from './redux/selectors';
import { Typography } from '@mui/material';
import { FormatAsTwoDecimals } from './utils/Format'
import GaTracker from "./utils/GaTracker";
import Footer from "./components/Footer/Footer"
import Header from "./components/Header/Header"
import MobileHeader from "./components/MobileHeader/MobileHeader"

import MainPage from './components/MainPage/MainPage';
import useWindowDimensions from './hooks/useWindowDimensions'
import { defaultTooltips } from './data/defaultTooltips';

import { gql, GraphQLClient } from 'graphql-request'
import { useRef } from 'react';
const queryString = require('query-string');

function App() {
  const [skip, setSkip] = useState(true)
  const zipcode = useSelector(getZipcode)
  const activeTrim = useSelector(getActiveTrim)
  const dispatch = useDispatch()
  const { width } = useWindowDimensions();
  const [tooltipsData, setToolTipsData] = useState({})
  
  const lastClickTimeRef = useRef(Date.now());

  const fireWidgetAnalytics = (event) => {
    const now = Date.now();
    if (now - lastClickTimeRef.current < 1000) {
      // Ignore click events that happen within 1 second of the previous one.
      return;
    }

    lastClickTimeRef.current = now;

    GaTracker.trackEvent({
      category: "FAQ",
      action: event,
      label: `FAQ click from ${activeTrim.handle}`,
    });
  }

  var element = document.querySelector('iframe')
  if(element){
    if(width < 1251) {
      element.style.cssText += 'bottom: 100px;'
    }

    if (element.contentWindow.document.readyState === 'complete') {
      var iframeDocument = element.contentWindow.document;
  
      iframeDocument.addEventListener('click', function(event) {
        if (!event.detail || event.detail === 1) {
          fireWidgetAnalytics('Clicked FAQ  widget')
        }
      });
    }
  }


  useEffect(() => {
    const query = queryString.parse(window.location.search);
    let handle = "";
    if(Array.isArray(query.trims)) {
      handle = JSON.parse(query.trims[0]);
    } else {
      handle = JSON.parse(query.trims);
    }
    GaTracker.initialize();
    GaTracker.trackPage(handle.handle);
  }, [])

  useEffect(() => {
    const query = queryString.parse(window.location.search);
    let trims = [];
    let activeTrim = [];
    if(Array.isArray(query.trims)) {
      query.trims.map(trim => trims.push(JSON.parse(trim)));
      activeTrim.push(JSON.parse(query.trims[0]));
    } else {
      trims.push(JSON.parse(query.trims));
      activeTrim.push(JSON.parse(query.trims));
    }
    dispatch(updateZipcode(query?.zipcode))
    if (trims) {
      dispatch(updateTrims(trims))
      dispatch(updateActiveTrim(activeTrim[0]))
      setSkip(false)
    }
  }, [dispatch, setSkip])

  const { data: vehicleData, error: vehicleError, isLoading: vehicleLoading } = useGetVehicleQuery(
    {
      handle: activeTrim,
      postcode: zipcode
    },
    {skip}
  )

  const { data: locationData, error: locationError, isLoading: locationLoading } = useGetLocationQuery({
    postcode: zipcode
  })

  if (vehicleError || locationError) {
    dispatch(updateZipcode(90210))
  }

  useEffect(() => {
    if (locationData) {
      const electricityPrice = FormatAsTwoDecimals(locationData.location.regional_fuel_cost[0].electricity / 100)
      const fuelPrice = FormatAsTwoDecimals(locationData.location.regional_fuel_cost[0].gasoline)
      dispatch(updateElectricityPrice(electricityPrice))
      dispatch(updateFuelPrice(fuelPrice))
    }
  }, [locationData, dispatch])
  
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        className="tab-container"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

   //amplify
   useEffect(() => {
    const fetchTranslations = async (token) => {
      let endpoint = process.env.REACT_APP_AMPLIFY_STAGING_ENDPOINT;
      let apiKey = process.env.REACT_APP_AMPLIFY_STAGING_API_KEY;

      if (process.env.REACT_APP_ENVIRONMENT === "production") {
        endpoint = process.env.REACT_APP_AMPLIFY_PRODUCTION_ENDPOINT;
        apiKey = process.env.REACT_APP_AMPLIFY_PRODUCTION_API_KEY;
      }

      const graphQLClient = new GraphQLClient(endpoint, {
        headers: {
          "X-Api-Key": apiKey,
        },
      });

      let query = gql`
        {
          listVehicleTooltips {
            items {
              handle
              zipcode_tip
              annual_milage_tip
              comparison_mpg_tip
              electricity_price_tip
              fuel_price_tip
              incentives_tip
              savings_tip
              emissions_tip
              potentialIncentives_tip
              potentialSavings_tip
              trimStartingMSRP_tip
            }
            nextToken
          }
        }
      `;


      if (token) {
        query = gql`
          {
            listVehicleTooltips(nextToken: "${token}") {
              items {
                id
                handle
                incentivesTip
                zipcodeTip
                trimTip
                costOwnershipTip
                mileageTip
                mpgTip
                electricityPriceTip
                fuelPriceTip
                potentialIncentivesTip
                potentialFuelSavingsTip
                emissionsTip
              }
              nextToken
            }
          }
        `;
      }

      const data = await graphQLClient.request(query);
      return data;
    };

    const setTranslations = async () => {
      const engTranslations = {};
      let token;

      while (token !== null) {
        let data = await fetchTranslations(token);

        data.listVehicleTooltips.items.forEach((translation) => {
          engTranslations[translation.handle] = translation;
        });
        token = data.listVehicleTooltips.nextToken;
      }
      setToolTipsData(engTranslations)
    };

    setTranslations();
  }, []);

  useEffect(() => {
    if(vehicleData && tooltipsData){
      const currentEv = vehicleData.vehicle.handle;
      const selectedEvTooltips = tooltipsData[currentEv]
      dispatch(updatetooltips(selectedEvTooltips))
    } else {
      dispatch(updatetooltips(defaultTooltips))

    }
  },[tooltipsData, vehicleData, dispatch])

  return (
  
    <div className="App">
      <Box sx={{ width: "100%" }}>
        {vehicleData && <> <Header vehicle={vehicleData.vehicle} />  <MobileHeader vehicle={vehicleData.vehicle} /> </>}
        {vehicleError || locationError ? (
          <>{vehicleError}</>
        ) : vehicleLoading || locationLoading ? (
          <Typography>Loading...</Typography>
        ) : vehicleData && locationData ? (
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <MainPage 
              vehicleIncentives={vehicleData.vehicle.incentives}
              state={locationData.location.region}
              vehicle={vehicleData.vehicle}
              activeTrim={activeTrim}
              location={locationData.location}
            /> 
          </Box>
        ) : null}
        <Footer isMobile={width < 1025}/>
      </Box>
    </div>

  );
}

export default App;


