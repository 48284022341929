import { Typography } from "@mui/material";
import CustomizedToolTip from "../components/CustomizedToolTip/CustomizedToolTip";
import IncentiveCard from "../components/IncentiveCard/IncentiveCard";
import {
  highestEligibleAmountIncentiveGivenType,
  incentiveSavingsOfGivenTypes,
} from "../functions/incentive/incentiveHelperFunctions";
import { FormatAsDollars } from "../utils/Format";
import './styles/incentives.scss'
import { getTooltips } from "../redux/selectors"
import { useSelector } from 'react-redux';

const Incentives = ({ vehicleIncentives, state }) => {
  const tooltip = useSelector(getTooltips)

  const highestStateIncentive = highestEligibleAmountIncentiveGivenType(
    vehicleIncentives,
    "state"
  );
  const highestUtilityIncentive = highestEligibleAmountIncentiveGivenType(
    vehicleIncentives,
    "Power Supplier"
  );

  const incentiveSavings = incentiveSavingsOfGivenTypes(vehicleIncentives, [
    "Country",
    "state",
    "power supplier",
  ]);

  const federalStateUtilityIncentiveSavings = FormatAsDollars(incentiveSavings);

  const incentiveList = [
    {
      grantorType: "State",
      amount: highestStateIncentive
        ? highestStateIncentive?.evaluation?.amount_in_purchase
        : 0,
      description: highestStateIncentive
        ? `Max potential incentives from ${state}`
        : `State incentives are not available at this time`,
      link: highestStateIncentive?.details_url,
      name: highestStateIncentive?.name,
    },
    {
      grantorType: "LOCAL Utility",
      amount: highestUtilityIncentive
        ? highestUtilityIncentive?.evaluation?.amount_in_purchase
        : 0,
      description: highestUtilityIncentive
        ? `Max potential incentives available in your area`
        : `Utility incentives are not available at this time`,
      link: highestUtilityIncentive?.details_url,
      name: highestUtilityIncentive?.name,
    },
  ];

  const renderIncentives = incentiveList.map((incentive, index, array) => {
    const isLast = index === array.length - 1 
    return (
      <IncentiveCard
        key={incentive.grantorType}
        grantor={incentive.grantorType}
        amount={FormatAsDollars(incentive.amount)}
        description={incentive.description}
        link={incentive.link}
        isLast={isLast}
      />
    );
  });

  const incentiveText = incentiveSavings > 0 
  ? <>You may be eligible for as much as <span style={{color: '#AF6249'}}>{federalStateUtilityIncentiveSavings}</span> in incentives, like vehicle tax credits and rebates.</>
  : 'Vehicle tax credits and rebate programs are always updating. Check back for future incentives.'

  return (
    <div id='incentivesTab'>
      <Typography sx={{color: '#141414', fontSize: '24px', fontWeight: 400, fontFamily: "GenesisSansHead", whiteSpace: "nowrap"}}>
        POTENTIAL INCENTIVES<CustomizedToolTip title={tooltip?.incentives_tip} />
      </Typography>
      <Typography className="incentive-text-box">
        {incentiveText}
      </Typography>
      {renderIncentives}
    </div>
  )
};

export default Incentives;
